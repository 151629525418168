@use 'sass:map';

@use '@shared/styles/config/variables' as *;

.root {
  padding: map.get($spacers, 'spacing', '3') 0;
}

.list {
  align-items: center;
  color: map.get($colors, 'blackbird-700');
  display: flex;
  flex-wrap: wrap;
  font-size: map.get($typography, 'size', '1');

  li {
    align-items: center;
    display: flex;

    &:not(:last-child) {
      margin-right: map.get($spacers, 'spacing', '2');
    }

    &:last-child {
      color: map.get($colors, 'charcoal-charlie');
    }
  }

  a {
    &:not(:hover) {
      color: inherit;
    }

    &:hover {
      color: map.get($colors, 'charcoal-charlie');
      text-decoration: none;
    }
  }
}
