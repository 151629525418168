@use 'sass:map';

@use '@shared/styles/config/variables' as *;

.root {
  display: flex;
  flex-direction: column;
  font-family: map.get($typography, 'family', 'noto-sans');
  min-height: 100vh;
}
