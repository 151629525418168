@use 'sass:map';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;

.layout {
  main {
    display: flex;
  }
}

.root {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  padding: map.get($spacers, 'spacing', '5') map.get($spacers, 'spacing', '4');

  @include breakpoint('lg', 'min') {
    padding: map.get($spacers, 'spacing', '8');
  }
}

.content {
  align-items: center;
  background-color: map.get($colors, 'lily-of-the-valley');
  border-radius: map.get($radiuses, '4');
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  justify-content: center;
  padding: map.get($spacers, 'spacing', '4') 0;
  text-align: center;

  @include breakpoint('lg', 'min') {
    padding: map.get($spacers, 'spacing', '5') 0;
  }

  p {
    margin-top: map.get($spacers, 'spacing', '4');
  }

  a {
    margin-top: map.get($spacers, 'spacing', '6');
  }
}

.code {
  display: block;
  font-family: map.get($typography, 'family', 'poppins');
  font-size: 7rem;
  font-weight: map.get($typography, 'weight', 'bold');
  line-height: map.get($spacers, 'leading', '1');
  pointer-events: none;
  position: relative;

  @include breakpoint('sm', 'min') {
    font-size: 10rem;
  }

  @include breakpoint('md', 'min') {
    font-size: 13rem;
  }

  @include breakpoint('lg', 'min') {
    font-size: 16rem;
  }

  @include breakpoint('xl', 'min') {
    font-size: 19rem;
  }

  & + * {
    width: 100%;
  }
}

.line {
  background-color: map.get($colors, 'blue-moon');
  border-radius: map.get($radiuses, '6') 0 0 map.get($radiuses, '6');
  height: 17.5%;
  left: -10%;
  position: absolute;
  top: 48%;
  transform: translateY(-50%);
  width: 100vw;

  &::before,
  &::after {
    border-radius: inherit;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
  }

  &::before {
    background-color: map.get($colors, 'purple-rain');
    bottom: calc(100% + 30%);
  }

  &::after {
    background-color: map.get($colors, 'yellow-submarine');
    top: calc(100% + 30%);
  }
}

.number {
  color: map.get($colors, 'white');
  position: relative;
}
