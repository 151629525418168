@use 'sass:map';

@use '@shared/styles/config/variables' as *;

.root {
  border: map.get($borders, '2') dashed map.get($colors, 'purple-rain');
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
}

.title {
  background-color: map.get($colors, 'purple-rain');
  color: map.get($colors, 'white');
  font-size: map.get($typography, 'size', '1');
  left: - map.get($spacers, 'spacing', '4');
  padding: map.get($spacers, 'spacing', '2') map.get($spacers, 'spacing', '6');
  position: absolute;
  text-transform: uppercase;
  top: - map.get($spacers, 'spacing', '4');
  transform-origin: center center;
  transform: rotate(-45deg) translateX(-25%);
}
