@use 'sass:map';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;

.root {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: map.get($spacers, 'spacing', '7') 0;

  @include breakpoint('lg', 'min') {
    padding: map.get($spacers, 'spacing', '12') 0;
  }

  @include breakpoint('xl', 'min') {
    padding: map.get($spacers, 'spacing', '15') 0;
  }

  ol {
    align-items: center;
    display: flex;

    li {
      list-style: none;

      &:not(:last-child) {
        margin-right: map.get($spacers, 'spacing', '4');
      }
    }
  }
}

.pages {
  align-items: center;
  display: flex;
  padding: 0 map.get($spacers, 'spacing', '4');
}

.page {
  @include breakpoint('md', 'max') {
    display: none;
  }

  span {
    margin: 0 map.get($spacers, 'spacing', '4');
  }
}
